import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import {ApisInfo} from "@/store/modules/ApiModule";

interface myvar {
    data: Array<string>;
}

export interface PosProcessInfo {
    myList: myvar;
    myDetails: Array<string>;
    errors: Array<string>;
}

@Module
export default class PosProcessModule extends VuexModule implements PosProcessInfo {
    errors = [];
    myList = {} as myvar;
    myDetails = [];

    @Action
    [Actions.POS_PROCESS_START_PROCESS](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/posprocess/startprocess", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload.data = data.data;
                    //this.context.commit(Mutations.SET_BANKS_LIST, payload);
                    resolve(payload);
                })
                .catch(({ response }) => {
                    reject(payload);
                });
        });
    }

    @Action
    [Actions.POS_PROCESS_END_PROCESS](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/posprocess/endProcess", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload.data = data.data;
                    //this.context.commit(Mutations.SET_BANKS_LIST, payload);
                    resolve(payload);
                })
                .catch(({ response }) => {
                    reject(payload);
                });
        });
    }

    @Action
    [Actions.POS_PROCESS_SEND_FORM_NOMUPAY](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/posprocess/sendFormNomupay", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload.data = data.data;
                    //this.context.commit(Mutations.SET_BANKS_LIST, payload);
                    resolve(payload);
                })
                .catch(({ response }) => {
                    reject(payload);
                });
        });
    }

}