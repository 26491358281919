import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import AppCore from "@/core/appcore/AppCore";

export interface User {
  name: string;
  surname: string;
  email: string;
  password: string;
  token: string;
}

export interface UserAuthInfo {
  errors: Array<string>;
  user: User;
  isAuthenticated: boolean;
}

@Module
export default class AuthModule extends VuexModule implements UserAuthInfo {
  errors = [];
  user = {} as User;
  isAuthenticated = !!JwtService.getToken();

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {

    return this.isAuthenticated;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors(): Array<string> {
    return this.errors;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user.token = user.data.Logincode;
    this.user.name = user.data.Username;
    this.errors = [];

    JwtService.saveToken(user.data.Logincode);
    console.log("SET_AUTH Token " + user.data.Logincode);
    JwtService.saveUserName(user.data.Username);
    JwtService.saveUserInfo(user.data);
    console.log("SET_AUTH User Data " + JSON.stringify(user.data));


  //  JwtService.saveToken(user.Logincode);

   // JwtService.saveUserInfo(user);
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = user;
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.errors = [];
    JwtService.destroyToken();
  }

  @Action
  [Actions.LOGIN](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/userlogin", credentials)
        .then(({ data }) => {
          if(data.status == 200 && data.code == "MSAPICD-003") {
            this.context.commit(Mutations.SET_AUTH, data);
            resolve();
          }else{
            const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
            this.context.commit(Mutations.SET_ERROR, myError);
            reject();
          }
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("registration", credentials)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("forgot_password", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }

  @Action
  [Actions.VERIFY_AUTH](payload) {

    //console.log("VERIFY_AUTH START");
    //console.log(payload);

    if (JwtService.getToken()) {
     // console.log("VERIFY_AUTH v1");
      ApiService.setHeader();
     // console.log("VERIFY_AUTH v1A");
     // console.log("VERIFY_AUTH v2 "+JSON.stringify(JwtService.getUserInfo() ));
      ApiService.post("verifyToken", payload)
          .then(({ data }) => {
        //    console.log("Verify Token V1 status: "+data.status);
            if (data.status!=200) {
              this.context.commit(Mutations.PURGE_AUTH);
              AppCore.prsCore_User.navigateToSignIn("CF_NAVSIGIN_AUTH_VERIFY_AUTH");
            }
          })
          .catch(({ response }) => {
            //console.log("Verify Token V2");
            this.context.commit(Mutations.SET_ERROR, "Login ERROR");
            this.context.commit(Mutations.PURGE_AUTH);
          });
    }else {
      this.context.commit(Mutations.PURGE_AUTH);
      AppCore.prsCore_User.navigateToSignIn("CF_NAVSIGIN_AUTH_VERIFY_AUTH_V2");
    }
  }

  @Action
  [Actions.UPDATE_USER](payload) {
    ApiService.setHeader();
    return new Promise<void>((resolve, reject) => {
      ApiService.post("update_user", payload)
        .then(({ data }) => {
          this.context.commit(Mutations.SET_USER, data);
          resolve();
        })
        .catch(({ response }) => {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          reject();
        });
    });
  }
}
