import ApiService from "@/core/services/ApiService";
import ApiService2 from "@/core/services/ApiService2";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

interface myvar {
    data: Array<string>;
}

export interface UserInfo {
    myList: myvar;
    myDetails: Array<string>;
    errors: Array<string>;
}

@Module
export default class UserModule extends VuexModule implements UserInfo {
    errors = [];
    myList = {} as myvar;
    myDetails = [];

    get userList(): myvar {
        return this.myList;
    }

    get userDetails(): Array<string> {
        return this.myDetails;
    }

    get getUserErrors(): Array<string> {
        return this.errors;
    }

    @Mutation
    [Mutations.USER_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_USER_LIST](payload) {
        this.myList = payload;
    }

    @Mutation
    [Mutations.SET_USER_DETAILS](payload) {
        this.myDetails = payload;

    }

    @Action
    [Actions.USER_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/systemusers/getList", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_USER_LIST, payload);
                    resolve(payload);
                })
                .catch(({ response }) => {
                    reject(payload);
                });
        });

    }

    @Action
    [Actions.USER_DETAILS](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/systemusers/getDetails", payload)
                .then(({ data }) => {
                    payload = data.data;
                    //console.log(payload);
                    this.context.commit(Mutations.SET_USER_DETAILS, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }

    @Action
    [Actions.USER_INSERT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/systemusers/insert", credentials)
            //ApiService2.post("/fup/put.php", credentials)
                .then(({ data }) => {
                    if(data.status == 201) {
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.USER_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: Kullanıcı Ekleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.USER_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.USER_EDIT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/systemusers/update", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.USER_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: Kullanıcı güncelleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.USER_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.USER_DELETE](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/systemusers/delete", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.USER_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: Kullanıcı Silme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.USER_ERROR, myError);
                    reject();
                });
        });
    }
}