import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {HotelInfo} from "@/store/modules/HotelModule";
import AppCore from "@/core/appcore/AppCore";

interface myvar {
    data: Array<string>;
}

export interface FlightReservationInfo {


    stepsBasket:Array<string>;
    searchParams:Array<string>;

    arrReservations: myvar;
    addItem: Array<string>;
    addInstallment: Array<string>;
    searchCode: string;
    arrPackageResult: myvar;
    arrHotelRateResult: myvar;
    arrTransfers: myvar;
    arrMakeReservationResult: myvar;

    curStep: number;
    curNext: number;
    curPrev: number;

    isSearching : boolean;

}

@Module
export default class FlightReservationModule extends VuexModule implements FlightReservationInfo {

    stepsBasket = [];
    searchParams = [];

    arrReservations = {} as myvar;
    addItem = [];
    addInstallment= [];
    searchCode = "";
    arrPackageResult = {} as myvar;
    arrHotelRateResult = {} as myvar;
    arrTransfers = {} as myvar;
    arrMakeReservationResult = {} as myvar;

    curStep= 0;
    curNext = 0;
    curPrev = 0;

    isSearching = false;

    get getFlightReservationStepsBasket(): Array<string> {
        return this.stepsBasket;
    }

    get getFlightReservationSearchParams(): Array<string> {
        return this.searchParams;
    }

    get getFlightReservationList(): myvar {
        return this.arrReservations;
    }

    get getFlightReservationAddItem(): Array<string> {
        return this.addItem;
    }

    get getFlightReservationAddInstallment(): Array<string> {
        return this.addInstallment;
    }

    get getFlightReservationSearchCode(): string {
        return this.searchCode;
    }

    get getFlightReservationPackageResult(): myvar {
        return this.arrPackageResult;
    }

    get getMakeReservationResult(): myvar {
        return this.arrMakeReservationResult;
    }

    get getHotelRateResult(): myvar {
        return this.arrHotelRateResult;
    }

    get getFlightReservationTransfers(): myvar {
        return this.arrTransfers;
    }

    get getFlightReservationCurStep(): number {
        return this.curStep;
    }

    get getFlightReservationCurPrev(): number {
        return this.curPrev;
    }

    get getFlightReservationCurNext(): number {
        return this.curNext;
    }

    get getFlightReservationIsSearching(): boolean {
        return this.isSearching;
    }

    @Mutation
    [Mutations.SET_FLIGHT_RESERVATION_STEPS_BASKET](payload) {
        console.log("SET_FLIGHT_RESERVATION_STEPS_BASKET "+JSON.stringify(payload));
        this.stepsBasket = payload;
    }

    @Mutation
    [Mutations.SET_FLIGHT_RESERVATION_SEARCH_PARAMS](payload) {
        //console.log("SET_FLIGHT_RESERVATION_SEARCH_PARAMS "+JSON.stringify(payload));
        this.searchParams = payload;
    }

    @Mutation
    [Mutations.SET_FLIGHT_RESERVATION_LIST](payload) {
        this.arrReservations = payload;
    }

    @Mutation
    [Mutations.SET_FLIGHT_RESERVATION_ADD](payload) {
        this.addItem = payload;
    }

    @Mutation
    [Mutations.SET_FLIGHT_RESERVATION_ADD_INSTALLMENT](payload) {
        this.addInstallment = payload;
    }

    @Mutation
    [Mutations.SET_FLIGHT_RESERVATION_PACKAGE_RESULT](payload) {
        this.arrPackageResult = payload;
    }

    @Mutation
    [Mutations.SET_FLIGHT_RESERVATION_SUMMARY_HOTEL_RATE](payload) {
        this.arrHotelRateResult = payload;
    }

    @Mutation
    [Mutations.SET_FLIGHT_RESERVATION_MAKE_RESERVATION](payload) {
        this.arrMakeReservationResult = payload;
    }

    @Mutation
    [Mutations.SET_FLIGHT_RESERVATION_TRANSFERS](payload) {
        this.arrTransfers = payload;
    }

    @Mutation
    [Mutations.SET_FLIGHT_RESERVATION_SEARCH_CODE](payload) {
        this.searchCode = payload;
    }

    @Mutation
    [Mutations.SET_FLIGHT_RESERVATION_CUR_STEP](payload) {
        console.log("SET_FLIGHT_RESERVATION_CUR_STEP "+payload);
        this.curStep = payload;
    }

    @Mutation
    [Mutations.SET_FLIGHT_RESERVATION_CUR_PREV](payload) {
        console.log("SET_FLIGHT_RESERVATION_CUR_PREV "+payload);
        this.curPrev = payload;
    }

    @Mutation
    [Mutations.SET_FLIGHT_RESERVATION_CUR_NEXT](payload) {
        console.log("SET_FLIGHT_RESERVATION_CUR_NEXT "+this.curNext+" to "+payload);
        this.curNext = payload;
    }

    @Mutation
    [Mutations.SET_FLIGHT_RESERVATION_IS_SEARCHING](payload) {
        this.isSearching = payload;
    }

    @Action
    [Actions.FLIGHT_RESERVATION_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/newreservation/search", payload)
                .then(({ data }) => {
                    //console.log("FLIGHT_RESERVATION_LIST v2 data: "+data);

                    const vvPayload = JSON.parse(JSON.stringify(payload));  //clone
                    this.context.commit(Mutations.SET_FLIGHT_RESERVATION_SEARCH_PARAMS, vvPayload);
                    AppCore.prsCore_Flight.setSearchParams(vvPayload);

                    if (data.data!==undefined) {



                        payload.data = data.data;
                        this.context.commit(Mutations.SET_FLIGHT_RESERVATION_LIST, payload);

                        if (data.data.searchCode !== undefined) {
                            AppCore.prsCore_Flight.setSearchCode(data.data.searchCode, data.data.ccvVoucherNo);
                            this.context.commit(Mutations.SET_FLIGHT_RESERVATION_SEARCH_CODE, data.data.searchCode);
                        }
                    }

                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });
    }

    @Action
    [Actions.FLIGHT_RESERVATION_ADD](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/newreservation/addItem", payload)
                .then(({ data }) => {
                    //console.log("FLIGHT_RESERVATION_ADD data: "+data);
                    if (data.data!==undefined) {
                        payload.data = data.data;
                        this.context.commit(Mutations.SET_FLIGHT_RESERVATION_ADD, payload);
                    }
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });
    }

    @Action
    [Actions.FLIGHT_RESERVATION_ADD_INSTALLMENT](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/newreservation/addInstallment", payload)
                .then(({ data }) => {
                    console.log("FLIGHT_RESERVATION_ADD_INSTALLMENT data: "+data);
                    if (data.data!==undefined) {
                        payload.data = data.data;
                        this.context.commit(Mutations.SET_FLIGHT_RESERVATION_ADD_INSTALLMENT, payload);
                    }
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });
    }

    @Action
    [Actions.FLIGHT_RESERVATION_MAKE_RESERVATION](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/newreservation/makeReservation", payload)
                .then(({ data }) => {
                    if (data.data!==undefined) {
                        payload.data = data.data;
                        this.context.commit(Mutations.SET_FLIGHT_RESERVATION_MAKE_RESERVATION, payload);
                    }
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });
    }

    @Action
    [Actions.FLIGHT_RESERVATION_PACKAGE_RESULT](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/newreservation/getPackageResult", payload)
                .then(({ data }) => {
                    //console.log("FLIGHT_RESERVATION_PACKAGE_RESULT v1 data: "+data);
                    if (data.data!==undefined) {
                        payload.data = data.data;
                        this.context.commit(Mutations.SET_FLIGHT_RESERVATION_PACKAGE_RESULT, payload);
                    }
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });
    }

    @Action
    [Actions.FLIGHT_RESERVATION_SUMMARY_HOTEL_RATE](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/newreservation/setSummaryHotelRate", payload)
                .then(({ data }) => {
                    //console.log("FLIGHT_RESERVATION_PACKAGE_RESULT v1 data: "+data);
                    if (data.data!==undefined) {
                        payload.data = data.data;
                        this.context.commit(Mutations.SET_FLIGHT_RESERVATION_SUMMARY_HOTEL_RATE, payload);
                    }
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });
    }

    @Action
    [Actions.FLIGHT_RESERVATION_TRANSFERS](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/newreservation/getTransfers", payload)
                .then(({ data }) => {
                    if (data.data!==undefined) {
                        payload.data = data.data;
                        this.context.commit(Mutations.SET_FLIGHT_RESERVATION_TRANSFERS, payload);
                    }
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });
    }

    @Action
    [Actions.FLIGHT_RESERVATION_SEND_EMAIL](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/newreservation/sendemail", payload)
                .then(({ data }) => {
                    payload.resdata = data;
                    resolve(payload);
                })
                .catch(({ response }) => {
                    reject();
                });
        });
    }

    @Action
    [Actions.FLIGHT_RESERVATION_STEPS_BASKET](payload) {
        this.context.commit(Mutations.SET_FLIGHT_RESERVATION_STEPS_BASKET, payload);
    }

    @Action
    [Actions.FLIGHT_RESERVATION_CUR_STEP](payload) {
        this.context.commit(Mutations.SET_FLIGHT_RESERVATION_CUR_STEP, payload);
    }

    @Action
    [Actions.FLIGHT_RESERVATION_CUR_NEXT](payload) {
        this.context.commit(Mutations.SET_FLIGHT_RESERVATION_CUR_NEXT, payload);
    }

    @Action
    [Actions.FLIGHT_RESERVATION_CUR_PREV](payload) {
        this.context.commit(Mutations.SET_FLIGHT_RESERVATION_CUR_PREV, payload);
    }

    @Action
    [Actions.FLIGHT_RESERVATION_IS_SEARCHING](payload) {
        this.context.commit(Mutations.SET_FLIGHT_RESERVATION_IS_SEARCHING, payload);
    }

}