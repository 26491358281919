import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/store";

interface myvar {
    data: Array<string>;
}

export interface AirportsInfo {
    myList: myvar;
    myList2: myvar;
    myDetails: Array<string>;
    errors: Array<string>;
}

@Module
export default class AirportsModule extends VuexModule implements AirportsInfo {
    errors = [];
    myList = {} as myvar;
    myList2 = {} as myvar;
    myDetails = [];

    get airportsList(): myvar {
        return this.myList;
    }

    get airportsList2(): myvar {
        return this.myList2;
    }

    get airportsDetails(): Array<string> {
        return this.myDetails;
    }

    get getAirportsErrors(): Array<string> {
        return this.errors;
    }

    @Mutation
    [Mutations.AIRPORTS_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_AIRPORTS_LIST](payload) {
        console.log("SET_AIRPORTS_LIST payload: "+JSON.stringify(payload));
        //CallFrom
        if (payload.CallFrom===undefined || payload.CallFrom==="FROM") {
            this.myList = payload;
        } else {
            this.myList2 = payload;
        }

    }

    @Mutation
    [Mutations.SET_AIRPORTS_DETAILS](payload) {
        this.myDetails = payload;

    }

    @Action
    [Actions.AIRPORTS_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/airports/getList", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_AIRPORTS_LIST, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }

    @Action
    [Actions.AIRPORTS_LIST_ALL](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/airports/getListAll", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_AIRPORTS_LIST, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });
    }

    @Action
    [Actions.AIRPORTS_DETAILS](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/airports/get", payload)
                .then(({ data }) => {
                    payload = data.data;
                    //console.log(payload);
                    this.context.commit(Mutations.SET_AIRPORTS_DETAILS, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }

    @Action
    [Actions.AIRPORTS_INSERT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/airports/insert", credentials)
            //ApiService2.post("/fup/put.php", credentials)
                .then(({ data }) => {
                    if(data.status == 201) {
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.AIRPORTS_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: HAVALİMANI Ekleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.AIRPORTS_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.AIRPORTS_EDIT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/airports/update", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {

                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.AIRPORTS_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: HAVALİMANI güncelleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.AIRPORTS_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.AIRPORTS_DELETE](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/airports/delete", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.AIRPORTS_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: HAVALİMANI Silme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.AIRPORTS_ERROR, myError);
                    reject();
                });
        });
    }
}