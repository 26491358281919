import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {CurRateInfo} from "@/store/modules/CurRateModule";

interface myvar {
    data: Array<string>;
}


export interface ReservationPaymentInfo {
    myList: myvar;
    myDetails: Array<string>;
    errors: Array<string>;
}

@Module
export default class ReservationPaymentModule extends VuexModule implements ReservationPaymentInfo {
    errors = [];
    myList = {} as myvar;
    myDetails = [];

    get getReservationPaymentList(): myvar {
        return this.myList;
    }

    get getReservationPaymentDetails(): Array<string> {
        return this.myDetails;
    }

    get getReservationPaymentErrors(): Array<string> {
        return this.errors;
    }

    @Mutation
    [Mutations.SET_RESERVATION_PAYMENTS_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_RESERVATION_PAYMENTS_LIST](payload) {
        this.myList = payload;
    }

    @Mutation
    [Mutations.SET_RESERVATION_PAYMENTS_DETAILS](payload) {
        this.myDetails = payload;
    }

    @Action
    [Actions.RESERVATION_PAYMENT_DELETE](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/reservationpayments/delete", payload)
                .then(({ data }) => {
                    if(data.status == 200) {
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.SET_RESERVATION_PAYMENTS_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: Silme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.SET_RESERVATION_PAYMENTS_ERROR, myError);
                    reject();
                });
        });
    }



}