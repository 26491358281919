import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/store";

interface myvar {
    data: Array<string>;
}

export interface HotelKickBacksInfo {
    myList: myvar;
    myDetails: Array<string>;
    errors: Array<string>;
}

@Module
export default class HotelKickBacksModule extends VuexModule implements HotelKickBacksInfo {
    errors = [];
    myList = {} as myvar;
    myDetails = [];

    get hotelKickBacksList(): myvar {
        return this.myList;
    }

    get hotelKickBacksDetails(): Array<string> {
        return this.myDetails;
    }

    get getHotelKickBacksErrors(): Array<string> {
        return this.errors;
    }

    @Mutation
    [Mutations.HOTEL_KICKBACKS_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_HOTEL_KICKBACKS_LIST](payload) {
        this.myList = payload;
    }

    @Mutation
    [Mutations.SET_HOTEL_KICKBACKS_DETAILS](payload) {
        this.myDetails = payload;

    }

    @Action
    [Actions.HOTEL_KICKBACKS_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/hotelkickbacks/getList", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_HOTEL_KICKBACKS_LIST, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }



    @Action
    [Actions.HOTEL_KICKBACKS_DETAILS](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/hotelkickbacks/get", payload)
                .then(({ data }) => {
                    payload = data.data;
                    console.log(payload);
                    this.context.commit(Mutations.SET_HOTEL_KICKBACKS_DETAILS, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }

    @Action
    [Actions.HOTEL_KICKBACKS_INSERT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/hotelkickbacks/insert", credentials)
            //ApiService2.post("/fup/put.php", credentials)
                .then(({ data }) => {
                    if(data.status == 201) {
                        if (credentials.HotelID) {
                            const payload = {
                            HotelID: credentials.HotelID
                        }
                            store.dispatch(Actions.HOTEL_KICKBACKS_LIST, payload);
                        }
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.HOTEL_KICKBACKS_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: OTEL KICK-BACK Ekleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.HOTEL_KICKBACKS_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.HOTEL_KICKBACKS_EDIT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/hotelkickbacks/update", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {
                        if (credentials.HotelID) {
                            const payload = {
                                HotelID: credentials.HotelID
                            }
                            store.dispatch(Actions.HOTEL_KICKBACKS_LIST, payload);
                        }
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.HOTEL_KICKBACKS_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: OTEL KICK-BACK güncelleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.HOTEL_KICKBACKS_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.HOTEL_KICKBACKS_DELETE](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/hotelkickbacks/delete", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {
                        if (credentials.HotelID) {
                            const payload = {
                                HotelID: credentials.HotelID
                            }
                            store.dispatch(Actions.HOTEL_KICKBACKS_LIST, payload);
                        }
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.HOTEL_KICKBACKS_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: OTEL KICK-BACK Silme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.HOTEL_KICKBACKS_ERROR, myError);
                    reject();
                });
        });
    }
}