import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/store";

interface myvar {
    data: Array<string>;
}

export interface HotelAllocationsInfo {
    myList: myvar;
    myDetails: Array<string>;
    errors: Array<string>;
}

@Module
export default class HotelAllocationsModule extends VuexModule implements HotelAllocationsInfo {
    errors = [];
    myList = {} as myvar;
    myDetails = [];

    get hotelAllocationsList(): myvar {
        return this.myList;
    }

    get hotelAllocationsDetails(): Array<string> {
        return this.myDetails;
    }

    get getHotelAllocationsErrors(): Array<string> {
        return this.errors;
    }

    @Mutation
    [Mutations.HOTEL_ALLOCATIONS_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_HOTEL_ALLOCATIONS_LIST](payload) {
        this.myList = payload;
    }

    @Mutation
    [Mutations.SET_HOTEL_ALLOCATIONS_DETAILS](payload) {
        this.myDetails = payload;

    }

    @Action
    [Actions.HOTEL_ALLOCATIONS_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/hotelallocations/getList", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_HOTEL_ALLOCATIONS_LIST, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }



    @Action
    [Actions.HOTEL_ALLOCATIONS_DETAILS](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/hotelallocations/get", payload)
                .then(({ data }) => {
                    payload = data.data;
                    console.log(payload);
                    this.context.commit(Mutations.SET_HOTEL_ALLOCATIONS_DETAILS, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }

    @Action
    [Actions.HOTEL_ALLOCATIONS_INSERT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/hotelallocations/insert", credentials)
            //ApiService2.post("/fup/put.php", credentials)
                .then(({ data }) => {
                    if(data.status == 201) {
                        if (credentials.HotelID) {
                            const payload = {
                            HotelID: credentials.HotelID
                        }
                            store.dispatch(Actions.HOTEL_ALLOCATIONS_LIST, payload);
                        }
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.HOTEL_ALLOCATIONS_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: OTEL KONTENJAN Ekleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.HOTEL_ALLOCATIONS_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.HOTEL_ALLOCATIONS_EDIT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/hotelallocations/update", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {
                        if (credentials.HotelID) {
                            const payload = {
                                HotelID: credentials.HotelID
                            }
                            store.dispatch(Actions.HOTEL_ALLOCATIONS_LIST, payload);
                        }
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.HOTEL_ALLOCATIONS_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: OTEL KONTENJAN güncelleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.HOTEL_ALLOCATIONS_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.HOTEL_ALLOCATIONS_DELETE](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/hotelallocations/delete", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {
                        if (credentials.HotelID) {
                            const payload = {
                                HotelID: credentials.HotelID
                            }
                            store.dispatch(Actions.HOTEL_ALLOCATIONS_LIST, payload);
                        }
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.HOTEL_ALLOCATIONS_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: OTEL KONTENJAN Silme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.HOTEL_ALLOCATIONS_ERROR, myError);
                    reject();
                });
        });
    }
}