import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

interface Companies {

    data: Array<string>;
}

interface StoreInfo {
    companies: Companies;
}

@Module
export default class CompaniesModule extends VuexModule implements StoreInfo {
    companies = {} as Companies;

    /**
     * Get breadcrumb object for current page
     * @returns object
     */
    get getCompanies(): Companies {
        return this.companies;
    }


    @Mutation
    [Mutations.SET_COMPANY_LIST](payload) {
        this.companies = payload;
    }


    @Action
    [Actions.COMPANY_LIST](payload) {

        return new Promise<void>((resolve, reject) => {
            ApiService.post("/companies/getListAll", payload)
                .then(({ data }) => {
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_COMPANY_LIST, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }



}
