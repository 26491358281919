import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

interface myvar {
    data: Array<string>;
}

export interface ExportToPdfsInfo {
    myList: myvar;
    myDetails: Array<string>;
    errors: Array<string>;
}

@Module
export default class ExportToPdfModule extends VuexModule implements ExportToPdfsInfo {
    errors = [];
    myList = {} as myvar;
    myDetails = [];

    @Action
    [Actions.EXPORT_TO_PDF_AND_SEND_EMAIL](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/exporttopdf/exportToPdfAndSendEmail", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload.data = data.data;
                    resolve(payload);
                })
                .catch(({ response }) => {
                    reject(payload);
                });
        });
    }
    @Action
    [Actions.EXPORT_TO_PDF_SET_EMAIL_STATUS](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/exporttopdf/setEmailStatus", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload.data = data.data;
                    resolve(payload);
                })
                .catch(({ response }) => {
                    reject(payload);
                });
        });
    }
}