import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/store";

interface myvar {
    data: Array<string>;
}

export interface HotelRoomPriceInfo {
    myList: myvar;
    myDetails: Array<string>;
    errors: Array<string>;
}

@Module
export default class HotelRoomPriceModule extends VuexModule implements HotelRoomPriceInfo {
    errors = [];
    myList = {} as myvar;
    myDetails = [];

    get hotelRoomPriceList(): myvar {
        return this.myList;
    }

    get hotelRoomPriceDetails(): Array<string> {
        return this.myDetails;
    }

    get getHotelRoomPriceErrors(): Array<string> {
        return this.errors;
    }

    @Mutation
    [Mutations.HOTEL_ROOM_PRICE_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_HOTEL_ROOM_PRICE_LIST](payload) {
        this.myList = payload;
    }

    @Mutation
    [Mutations.SET_HOTEL_ROOM_PRICE_DETAILS](payload) {
        this.myDetails = payload;

    }

    @Action
    [Actions.HOTEL_ROOM_PRICE_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/hotelroomprices/getList", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_HOTEL_ROOM_PRICE_LIST, payload);
                    resolve(payload);
                })
                .catch(({ response }) => {
                    reject(payload);
                });
        });

    }



    @Action
    [Actions.HOTEL_ROOM_PRICE_DETAILS](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/hotelroomprices/get", payload)
                .then(({ data }) => {
                    payload = data.data;
                    //console.log(payload);
                    this.context.commit(Mutations.SET_HOTEL_ROOM_PRICE_DETAILS, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }

    @Action
    [Actions.HOTEL_ROOM_PRICE_INSERT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/hotelroomprices/insert", credentials)
            //ApiService2.post("/fup/put.php", credentials)
                .then(({ data }) => {
                    if(data.status == 201) {
                        if (credentials.HotelID) {
                            const payload = {
                            HotelID: credentials.HotelID
                        }
                            store.dispatch(Actions.HOTEL_ROOM_PRICE_LIST, payload);
                        }
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.HOTEL_ROOM_PRICE_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: ODA FİYAT Ekleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.HOTEL_ROOM_PRICE_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.HOTEL_ROOM_PRICE_EDIT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/hotelroomprices/update", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {
                        if (credentials.HotelID) {
                            const payload = {
                                HotelID: credentials.HotelID
                            }
                            store.dispatch(Actions.HOTEL_ROOM_PRICE_LIST, payload);
                        }
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.HOTEL_ROOM_PRICE_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: ODA FİYAT güncelleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.HOTEL_ROOM_PRICE_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.HOTEL_ROOM_PRICE_DELETE](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/hotelroomprices/delete", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {
                        if (credentials.HotelID) {
                            const payload = {
                                HotelID: credentials.HotelID
                            }
                            store.dispatch(Actions.HOTEL_ROOM_PRICE_LIST, payload);
                        }
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.HOTEL_ROOM_PRICE_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: ODA FİYAT Silme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.HOTEL_ROOM_PRICE_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.HOTEL_ROOM_PRICE_DELETE_BULK](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/hotelroomprices/deleteBulk", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {
                        if (credentials.HotelID) {
                            const payload = {
                                HotelID: credentials.HotelID
                            }
                            store.dispatch(Actions.HOTEL_ROOM_PRICE_LIST, payload);
                        }
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.HOTEL_ROOM_PRICE_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: ODA FİYAT Silme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.HOTEL_ROOM_PRICE_ERROR, myError);
                    reject();
                });
        });
    }
}
