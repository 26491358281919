import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

interface Regions {

    data: Array<string>;
}

interface StoreInfo {
    regions: Regions;
}

@Module
export default class RegionsModules extends VuexModule implements StoreInfo {
    regions = {} as Regions;

    /**
     * Get breadcrumb object for current page
     * @returns object
     */
    get RegionsList(): Regions {
        return this.regions;
    }


    @Mutation
    [Mutations.SET_REGIONS_LIST](payload) {
        this.regions = payload;
    }


    @Action
    [Actions.REGIONS_LIST](payload) {

        return new Promise<void>((resolve, reject) => {
            ApiService.post("regions/getListAll", payload)
                .then(({ data }) => {
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_REGIONS_LIST, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }



}
