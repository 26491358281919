import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/store";

interface myvar {
    data: Array<string>;
}

export interface InvoicesInfo {
    myList: myvar;
    myDetails: Array<string>;
    errors: Array<string>;
}

@Module
export default class InvoicesModule extends VuexModule implements InvoicesInfo {
    errors = [];
    myList = {} as myvar;
    myDetails = [];

    get invoicesList(): myvar {
        return this.myList;
    }

    get invoicesDetails(): Array<string> {
        return this.myDetails;
    }

    get getInvoicesErrors(): Array<string> {
        return this.errors;
    }

    @Mutation
    [Mutations.INVOICES_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_INVOICES_LIST](payload) {
        this.myList = payload;
    }

    @Mutation
    [Mutations.SET_INVOICES_DETAILS](payload) {
        this.myDetails = payload;

    }

    @Action
    [Actions.INVOICES_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/invoices/getList", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_INVOICES_LIST, payload);
                    resolve(payload);
                })
                .catch(({ response }) => {
                    reject(payload);
                });
        });

    }

    @Action
    [Actions.INVOICES_DETAILS](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/invoices/get", payload)
                .then(({ data }) => {
                    payload = data.data;
                    //console.log(payload);
                    this.context.commit(Mutations.SET_INVOICES_DETAILS, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }

    @Action
    [Actions.INVOICES_INSERT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/invoices/insert", credentials)
            //ApiService2.post("/fup/put.php", credentials)
                .then(({ data }) => {
                    if(data.status == 201) {
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.INVOICES_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: POS Ekleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.INVOICES_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.INVOICES_INSERT_WITH_ITEMS](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/invoices/insertwithitems", credentials)
                //ApiService2.post("/fup/put.php", credentials)
                .then(({ data }) => {
                    if(data.status == 201) {
                        resolve(data);
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.INVOICES_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: Ekleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.INVOICES_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.INVOICES_EDIT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/invoices/update", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {

                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.INVOICES_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: POS güncelleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.INVOICES_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.INVOICES_DELETE](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/invoices/delete", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.INVOICES_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: POS Silme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.INVOICES_ERROR, myError);
                    reject();
                });
        });
    }
}