import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";

class PrsCore_Reservation {

    public initComp() {
        console.log("initComp PrsCore_Reservation STARTED");
    }

    public send_ReservationProduct_Manual(prmReservationId, prmProductType, prmData) {
        const vvPayload = {
            ReservationId: prmReservationId,
            ProductType: prmProductType,
            Data: prmData,
        }
        //console.log("send_ReservationProduct_Manual "+JSON.stringify(vvPayload));
        store.dispatch(Actions.RESERVATION_PRODUCTS_INSERT_MANUAL, vvPayload);
    }

}

export default PrsCore_Reservation;