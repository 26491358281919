import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/store";

interface myvar {
    data: Array<string>;
}

export interface BankPosInstallmentInfo {
    myList: myvar;
    myDetails: Array<string>;
    errors: Array<string>;
}

@Module
export default class BankPosInstallmentModule extends VuexModule implements BankPosInstallmentInfo {
    errors = [];
    myList = {} as myvar;
    myDetails = [];

    get bankPosInstallmentList(): myvar {
        return this.myList;
    }

    get bankPosInstallmentDetails(): Array<string> {
        return this.myDetails;
    }

    get getBankPosInstallmentErrors(): Array<string> {
        return this.errors;
    }

    @Mutation
    [Mutations.BANKPOS_INSTALLMENT_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_BANKPOS_INSTALLMENT_LIST](payload) {
        this.myList = payload;
    }

    @Mutation
    [Mutations.SET_BANKPOS_INSTALLMENT_DETAILS](payload) {
        this.myDetails = payload;

    }

    @Action
    [Actions.BANKPOS_INSTALLMENT_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/bankposinstallment/getList", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_BANKPOS_INSTALLMENT_LIST, payload);
                    resolve(payload);
                })
                .catch(({ response }) => {
                    reject(payload);
                });
        });

    }

    @Action
    [Actions.BANKPOS_INSTALLMENT_WITH_COMMISSION_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/bankposinstallment/getListWithCommission", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_BANKPOS_INSTALLMENT_LIST, payload);
                    resolve(payload);
                })
                .catch(({ response }) => {
                    reject(payload);
                });
        });

    }

    @Action
    [Actions.BANKPOS_INSTALLMENT_DETAILS](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/bankposinstallment/get", payload)
                .then(({ data }) => {
                    payload = data.data;
                    //console.log(payload);
                    this.context.commit(Mutations.SET_BANKPOS_INSTALLMENT_DETAILS, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }

    @Action
    [Actions.BANKPOS_INSTALLMENT_INSERT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/bankposinstallment/insert", credentials)
            //ApiService2.post("/fup/put.php", credentials)
                .then(({ data }) => {
                    if(data.status == 201) {
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.BANKPOS_INSTALLMENT_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: POS Ekleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.BANKPOS_INSTALLMENT_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.BANKPOS_INSTALLMENT_EDIT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/bankposinstallment/update", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {

                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.BANKPOS_INSTALLMENT_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: POS güncelleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.BANKPOS_INSTALLMENT_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.BANKPOS_INSTALLMENT_DELETE](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/bankposinstallment/delete", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.BANKPOS_INSTALLMENT_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: POS Silme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.BANKPOS_INSTALLMENT_ERROR, myError);
                    reject();
                });
        });
    }
}