import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
import i18n from "@/core/plugins/i18n";

//imports for app initialization
//import MockAdapter from "@/core/mock/MockService";
import AppCore from "@/core/appcore/AppCore";
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";


import "@/core/plugins/keenthemes";
import "@/core/plugins/prismjs";
import "bootstrap";
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css';

import VueClickAway from "vue3-click-away";
import Maska from 'maska'
import SimpleTypeahead from 'vue3-simple-typeahead';
import "animate.css"

const app = createApp(App);


app.use(store);
app.use(router);
app.use(ElementPlus);


AppCore.init(app);
ApiService.init(app);
//MockAdapter.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);
app.use(Maska);
app.use(VueClickAway);
app.use(SimpleTypeahead);

app.mount("#app");

app.config.globalProperties.$filters = {
    currencyUSD(value) {
        return '$' + value
    }
}