class PrsCore_Invoice {

    public funcSummary_PrsStepsBasket;

    public testSt = "default";
    public arrCheckedProducts = [];
    public objFormValues = {};
    public objAccDetails = {};
    public objCurRate = {};



    public initComp() {
       console.log("PrsCore_Invoice initComp");
    }



}

export default PrsCore_Invoice;