import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";

interface myvar {
    data: Array<string>;
}

export interface ApisInfo {
    myList: myvar;
    myDetails: Array<string>;
    errors: Array<string>;
}

@Module
export default class ApiModule extends VuexModule implements ApisInfo {
    errors = [];
    myList = {} as myvar;
    myDetails = [];

    get getApisList(): myvar {
        return this.myList;
    }

    get getApisDetails(): Array<string> {
        return this.myDetails;
    }

    get getApisErrors(): Array<string> {
        return this.errors;
    }

    @Action
    [Actions.API_SEARCH](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/api/search", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload.data = data.data;
                    //this.context.commit(Mutations.SET_BANKS_LIST, payload);
                    resolve(payload);
                })
                .catch(({ response }) => {
                    reject(payload);
                });
        });
    }

    @Action
    [Actions.API_ADD_ITEM](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/api/addItem", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload.data = data.data;
                    //this.context.commit(Mutations.SET_BANKS_LIST, payload);
                    resolve(payload);
                })
                .catch(({ response }) => {
                    reject(payload);
                });
        });
    }

    @Action
    [Actions.API_GET_PACKAGE_RESULT](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/api/getPackageResult", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload.data = data.data;
                    //this.context.commit(Mutations.SET_BANKS_LIST, payload);
                    resolve(payload);
                })
                .catch(({ response }) => {
                    reject(payload);
                });
        });
    }

    @Action
    [Actions.API_GET_TRANSFERS](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/api/getTransfers", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload.data = data.data;
                    //this.context.commit(Mutations.SET_BANKS_LIST, payload);
                    resolve(payload);
                })
                .catch(({ response }) => {
                    reject(payload);
                });
        });
    }

}