import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/store";

interface myvar {
    data: Array<string>;
}

export interface BankAccountsInfo {
    myList: myvar;
    myDetails: Array<string>;
    errors: Array<string>;
}

@Module
export default class BankAccountsModule extends VuexModule implements BankAccountsInfo {
    errors = [];
    myList = {} as myvar;
    myDetails = [];

    get bankAccountsList(): myvar {
        return this.myList;
    }

    get bankAccountsDetails(): Array<string> {
        return this.myDetails;
    }

    get getBankAccountsErrors(): Array<string> {
        return this.errors;
    }

    @Mutation
    [Mutations.BANKACCOUNTS_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_BANKACCOUNTS_LIST](payload) {
        this.myList = payload;
    }

    @Mutation
    [Mutations.SET_BANKACCOUNTS_DETAILS](payload) {
        this.myDetails = payload;

    }

    @Action
    [Actions.BANKACCOUNTS_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/bankaccounts/getList", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_BANKACCOUNTS_LIST, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }

    @Action
    [Actions.BANKACCOUNTS_DETAILS](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/bankaccounts/get", payload)
                .then(({ data }) => {
                    payload = data.data;
                    //console.log(payload);
                    this.context.commit(Mutations.SET_BANKACCOUNTS_DETAILS, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }

    @Action
    [Actions.BANKACCOUNTS_INSERT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/bankaccounts/insert", credentials)
            //ApiService2.post("/fup/put.php", credentials)
                .then(({ data }) => {
                    if(data.status == 201) {
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.BANKACCOUNTS_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: BANKA HESABI Ekleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.BANKACCOUNTS_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.BANKACCOUNTS_EDIT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/bankaccounts/update", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {

                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.BANKACCOUNTS_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: BANKA HESABI güncelleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.BANKACCOUNTS_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.BANKACCOUNTS_DELETE](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/bankaccounts/delete", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.BANKACCOUNTS_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: BANKA HESABI Silme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.BANKACCOUNTS_ERROR, myError);
                    reject();
                });
        });
    }
}