import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

interface FileUpload {

    data: Array<string>;
}

interface StoreInfo {
    fileUpload: FileUpload;
}

@Module
export default class FileUploadModules extends VuexModule implements StoreInfo {
    fileUpload = {} as FileUpload;

    /**
     * Get breadcrumb object for current page
     * @returns object
     */
    get fileList(): FileUpload {
        return this.fileUpload;
    }


    @Mutation
    [Mutations.SET_FILE_UPLOAD](payload) {
        this.fileUpload = payload;
    }

    @Action
    [Actions.FILE_UPLOAD](payload) {
        return new Promise<void>((resolve, reject) => {

            fetch('http://localhost/fup/put.php', {
                method: "POST",
                body: payload
            })
                .then(() => {
                    console.log("Yükledi");
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }
}
