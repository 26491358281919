
import JwtService, {getUserInfo} from "@/core/services/JwtService";
import { useRouter } from "vue-router";

class PrsCore_User {

    public router;

    public initComp() {
        this.router = useRouter();
        console.log("initComp PrsCore_User STARTED");
    }

    public getUserInfo() {
        return JSON.parse( JwtService.getUserInfo());
    }

    public navigateToSignIn(prmCallFrom) {
        //console.log("navigateToSignIn prmCallFrom: "+prmCallFrom);
        //this.router = useRouter();
        //console.log("navigateToSignIn this.router: "+JSON.stringify(this.router));
        this.router.push({ name: this.getSignInUserPath(prmCallFrom) });
    }

    public getSignInUserPath(prmCallFrom) {
        //console.log("getSignInUserPath prmCallFrom: "+prmCallFrom);
        return "sign-in-user";
    }

}

export default PrsCore_User;