import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

interface myvar {

    data: Array<string>;
}

interface MarketsInfo {
    markets: myvar;
}

@Module
export default class MarketsModule extends VuexModule implements MarketsInfo {
    markets = {} as myvar;
    marketsC = {} as myvar;

    /**
     * Get breadcrumb object for current page
     * @returns object
     */
    get MarketsList(): myvar {
        return this.markets;
    }
    get MarketsListCache(): myvar {
        return this.marketsC;
    }

    @Mutation
    [Mutations.SET_MARKETS_LIST](payload) {
        this.markets = payload;
    }
    @Mutation
    [Mutations.SET_MARKETS_LIST_CACHE](payload) {
        this.marketsC = payload;
    }


    @Action
    [Actions.MARKETS_LIST](payload) {

        return new Promise<void>((resolve, reject) => {
            ApiService.post("/markets/getList", payload)
                .then(({ data }) => {
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_MARKETS_LIST, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }

    @Action
    [Actions.MARKETS_LIST_CACHE](payload) {

        return new Promise<void>((resolve, reject) => {
            ApiService.post("/markets/getListAll", payload)
                .then(({ data }) => {
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_MARKETS_LIST_CACHE, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }



}
