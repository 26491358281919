class PrsCore_Flight {

    public funcSummary_PrsStepsBasket;


    public ccvSearchProcessID = 0;
    public ccvSearchProcessID2 = 0;
    public ccvSearchCode = "";
    public ccvVoucherNo = "";
    public ccvSearchType = "";
    public theSearchParams = {} as any;
    public theStepsBasket = {} as any;

    public initComp() {
        this.resetSearchParams();
        this.resetStepsBasket();
    }

    public setSearchType(prmSearchType) {
        this.ccvSearchType = prmSearchType;
    }

    public resetSearchParams() {
        this.theSearchParams = {

        };
    }

    public setSearchParams(prmSearchParams) {
        console.log("PrsCore_Flight setSearchParams "+JSON.stringify(prmSearchParams));
        this.theSearchParams = prmSearchParams;
    }

    public setSearchCode(prmSearchCode, prmVoucherNo) {
        this.ccvSearchCode = prmSearchCode;
        this.ccvVoucherNo = prmVoucherNo;
    }

    public resetStepsBasket() {
        this.theStepsBasket = {
            Step1: {},
            Step2: {},
            Step3: {},
            Step4: {},
            Step5: {},
            Step6: {},
        };
        console.log("resetStepsBasket END");
    }

}

export default PrsCore_Flight;