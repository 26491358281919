import {Action, Mutation, Module, VuexModule} from "vuex-module-decorators";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

interface CommonData {
    data: Array<string>;
}


interface Params {
    imagePath: string;
}

interface StoreInfo {
    commonData: CommonData;
    countryData: CommonData;
    editorData: CommonData;
    params: Params;
}

@Module
export default class CommonModule extends VuexModule implements StoreInfo {
    commonData = {} as CommonData;
    countryData = {} as CommonData;
    editorData = {} as CommonData;
    params = {} as Params;

    /**
     * Get breadcrumb object for current page
     * @returns object
     */
    get CityList(): CommonData {
        return this.commonData;
    }

    get countryList(): CommonData {
        return this.countryData;
    }

    get editorContent(): CommonData {
        return this.editorData;
    }


    @Mutation
    [Mutations.SET_CITY_LIST](payload) {
        this.commonData = payload;
    }

    @Mutation
    [Mutations.SET_COUNTRY_LIST](payload) {
        this.countryData = payload;
    }

    @Mutation
    [Mutations.SET_EDITOR_CONTENT](payload) {
        this.editorData = payload;
    }


    @Action
    [Actions.CITY_LIST](payload) {

        return new Promise<void>((resolve, reject) => {
            ApiService.post("/cities/cityList", payload)
                .then(({data}) => {
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_CITY_LIST, payload);
                    resolve();
                })
                .catch(({response}) => {
                    reject();
                });
        });

    }

    @Action
    [Actions.COUNTRY_LIST](payload) {
        payload.data = [
            {
                "Code" : 'TR',
                "Title" : 'Türkiye',
            },
            {
                "Code" : 'TRNC',
                "Title" : 'Kuzey Kıbrıs',
            }];
        this.context.commit(Mutations.SET_COUNTRY_LIST, payload);


    }

    @Action
    [Actions.EDITOR_CONTENT](payload) {
        this.context.commit(Mutations.SET_EDITOR_CONTENT, payload);
    }

}
