import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

interface myvar {
    data: Array<string>;
}

export interface HotelInfo {
    myList: myvar;
    myListTo: myvar;
    myDetails: Array<string>;
    errors: Array<string>;
}

@Module
export default class HotelModule extends VuexModule implements HotelInfo {
    errors = [];
    myList = {} as myvar;
    myListTo = {} as myvar;
    myDetails = [];

    get hotelList(): myvar {
        return this.myList;
    }

    get hotelListTo(): myvar {
        return this.myListTo;
    }

    get hotelDetails(): Array<string> {
        return this.myDetails;
    }

    get getHotelErrors(): Array<string> {
        return this.errors;
    }

    @Mutation
    [Mutations.HOTEL_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_HOTEL_LIST](payload) {
        this.myList = payload;
    }

    @Mutation
    [Mutations.SET_HOTEL_LIST_TO](payload) {
        this.myListTo = payload;
    }

    @Mutation
    [Mutations.SET_HOTEL_DETAILS](payload) {
        this.myDetails = payload;

    }

    @Action
    [Actions.HOTEL_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/hotels/getList", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_HOTEL_LIST, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }

    @Action
    [Actions.HOTEL_LIST_ALL](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/hotels/getListAll", payload)   ///
                .then(({ data }) => {
                    //console.log(data);
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_HOTEL_LIST, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });
    }

    @Action
    [Actions.HOTEL_LIST_ALL_TO](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/hotels/getListAllTo", payload)   ///
                .then(({ data }) => {
                    //console.log(data);
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_HOTEL_LIST_TO, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });
    }

    @Action
    [Actions.HOTEL_DETAILS](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/hotels/get", payload)
                .then(({ data }) => {
                    payload = data.data;
                    //console.log(payload);
                    this.context.commit(Mutations.SET_HOTEL_DETAILS, payload);
                    resolve(payload);
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }

    @Action
    [Actions.HOTEL_INSERT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/hotels/insert", credentials)
            //ApiService2.post("/fup/put.php", credentials)
                .then(({ data }) => {
                    if(data.status == 201) {
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.HOTEL_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: OTEL Ekleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.HOTEL_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.HOTEL_EDIT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/hotels/update", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.USER_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: OTEL güncelleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.USER_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.HOTEL_DELETE](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/hotels/delete", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.HOTEL_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: OTEL Silme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.USER_ERROR, myError);
                    reject();
                });
        });
    }
}