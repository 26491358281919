import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

interface SystemUserGroup {

    data: Array<string>;
}

interface StoreInfo {
    systemUserGroup: SystemUserGroup;
}

@Module
export default class SystemUserGroupModules extends VuexModule implements StoreInfo {
    systemUserGroup = {} as SystemUserGroup;

    /**
     * Get breadcrumb object for current page
     * @returns object
     */
    get SystemUserGroupList(): SystemUserGroup {
        return this.systemUserGroup;
    }


    @Mutation
    [Mutations.SET_SYSTEM_USER_GROUP_LIST](payload) {
        this.systemUserGroup = payload;
    }

    @Action
    [Actions.SYSTEM_USER_GROUP_LISTALL](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("systemusergroup/getListAll", payload)
                .then(({ data }) => {
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_SYSTEM_USER_GROUP_LIST, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }
}
