import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

interface myvar {

    data: Array<string>;
}

interface LanguagesInfo {
    languages: myvar;
}

@Module
export default class LanguagesModule extends VuexModule implements LanguagesInfo {
    languages = {} as myvar;
    languagesC = {} as myvar;

    /**
     * Get breadcrumb object for current page
     * @returns object
     */
    get LanguagesList(): myvar {
        return this.languages;
    }
    get LanguagesListCache(): myvar {
        return this.languagesC;
    }

    @Mutation
    [Mutations.SET_LANGUAGES_LIST](payload) {
        this.languages = payload;
    }
    @Mutation
    [Mutations.SET_LANGUAGES_LIST_CACHE](payload) {
        this.languagesC = payload;
    }


    @Action
    [Actions.LANGUAGES_LIST](payload) {

        return new Promise<void>((resolve, reject) => {
            ApiService.post("/languages/getList", payload)
                .then(({ data }) => {
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_LANGUAGES_LIST, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }

    @Action
    [Actions.LANGUAGES_LIST_CACHE](payload) {

        return new Promise<void>((resolve, reject) => {
            ApiService.post("/languages/getListAll", payload)
                .then(({ data }) => {
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_LANGUAGES_LIST_CACHE, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }



}
