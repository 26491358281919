import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/store";

interface myvar {
    data: Array<string>;
}

export interface ExcelParseInfo {
    myList: myvar;
    myDetails: Array<string>;
    errors: Array<string>;
}

@Module
export default class ExcelParseModule extends VuexModule implements ExcelParseInfo {
    errors = [];
    myList = {} as myvar;
    myDetails = [];

    get excelParseTitleList(): myvar {
        return this.myList;
    }

    get excelParseTitleResult(): Array<string> {
        return this.myDetails;
    }

    get getExcelParseErrors(): Array<string> {
        return this.errors;
    }

    @Mutation
    [Mutations.EXCEL_PARSE_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_EXCEL_PARSE_TITLE](payload) {
        this.myList = payload;
    }

    @Mutation
    [Mutations.SET_EXCEL_PARSE_RESULT](payload) {
        this.myDetails = payload;

    }

    @Action
    [Actions.EXCEL_PARSE_TITLE](payload) {
        this.context.commit(Mutations.SET_EXCEL_PARSE_TITLE, payload);
    }

    @Action
    [Actions.EXCEL_PARSE_RESULT](payload) {
        this.context.commit(Mutations.SET_EXCEL_PARSE_RESULT, payload);
    }

}