import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/store";

interface myvar {
    data: Array<string>;
}

export interface MakeReservationsInfo {
    myList: myvar;
    myDetails: Array<string>;
    errors: Array<string>;
}

@Module
export default class MakeReservationsModule extends VuexModule implements MakeReservationsInfo {
    errors = [];
    myList = {} as myvar;
    myDetails = [];

    get makeReservationsDetails(): Array<string> {
        return this.myDetails;
    }

    @Mutation
    [Mutations.SET_MAKE_MANUEL_RESERVATIONS_DETAILS](payload) {
        this.myDetails = payload;

    }

    @Action
    [Actions.MAKE_MANUEL_RESERVATIONS](payload) {
        this.context.commit(Mutations.SET_MAKE_MANUEL_RESERVATIONS_DETAILS, payload);
    }


}