import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import store from "@/store";

interface myvar {
  data: Array<string>;
}

export interface BanksInfo {
  myList: myvar;
  myDetails: Array<string>;
  errors: Array<string>;
}

@Module
export default class BanksModule extends VuexModule implements BanksInfo {
  errors = [];
  myList = {} as myvar;
  myDetails = [];

  get banksList(): myvar {
    return this.myList;
  }

  get banksDetails(): Array<string> {
    return this.myDetails;
  }

  get getBanksErrors(): Array<string> {
    return this.errors;
  }

  @Mutation
  [Mutations.BANKS_ERROR](error) {
    this.errors = error;
  }

  @Mutation
  [Mutations.SET_BANKS_LIST](payload) {
    this.myList = payload;
  }

  @Mutation
  [Mutations.SET_BANKS_DETAILS](payload) {
    this.myDetails = payload;
  }

  @Action
  [Actions.BANKS_LIST](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/banks/getList", payload)
        .then(({ data }) => {
          //console.log(data);
          payload.data = data.data;
          this.context.commit(Mutations.SET_BANKS_LIST, payload);
          resolve();
        })
        .catch(({ response }) => {
          reject();
        });
    });
  }

  @Action
  [Actions.BANKS_DETAILS](payload) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/banks/get", payload)
        .then(({ data }) => {
          payload = data.data;
          //console.log(payload);
          this.context.commit(Mutations.SET_BANKS_DETAILS, payload);
          resolve();
        })
        .catch(({ response }) => {
          reject();
        });
    });
  }

  @Action
  [Actions.BANKS_INSERT](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/banks/insert", credentials)
        //ApiService2.post("/fup/put.php", credentials)
        .then(({ data }) => {
          if (data.status == 201) {
            resolve();
          } else {
            const myError = [
              "ERROR: " + data.messages + " (" + data.code + ")",
            ];
            this.context.commit(Mutations.BANKS_ERROR, myError);
            reject();
          }
        })
        .catch(({ response }) => {
          const myError = [
            "ERROR: BANKA Ekleme işlemi sırasında beklenmedik bir hata oluştu.",
          ];
          this.context.commit(Mutations.BANKS_ERROR, myError);
          reject();
        });
    });
  }

  @Action
  [Actions.BANKS_EDIT](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/banks/update", credentials)
        .then(({ data }) => {
          if (data.status == 200) {
            resolve();
          } else {
            const myError = [
              "ERROR: " + data.messages + " (" + data.code + ")",
            ];
            this.context.commit(Mutations.BANKS_ERROR, myError);
            reject();
          }
        })
        .catch(({ response }) => {
          const myError = [
            "ERROR: BANKA güncelleme işlemi sırasında beklenmedik bir hata oluştu.",
          ];
          this.context.commit(Mutations.BANKS_ERROR, myError);
          reject();
        });
    });
  }

  @Action
  [Actions.BANKS_DELETE](credentials) {
    return new Promise<void>((resolve, reject) => {
      ApiService.post("/banks/delete", credentials)
        .then(({ data }) => {
          if (data.status == 200) {
            resolve();
          } else {
            const myError = [
              "ERROR: " + data.messages + " (" + data.code + ")",
            ];
            this.context.commit(Mutations.BANKS_ERROR, myError);
            reject();
          }
        })
        .catch(({ response }) => {
          const myError = [
            "ERROR: BANKA Silme işlemi sırasında beklenmedik bir hata oluştu.",
          ];
          this.context.commit(Mutations.BANKS_ERROR, myError);
          reject();
        });
    });
  }
}
