import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

interface myvar {
    data: Array<string>;
}

export interface ReservationsTags {
    myList: myvar;
    myDetails: Array<string>;
    errors: Array<string>;
}

@Module
export default class ReservationsTagsModule extends VuexModule implements ReservationsTags {
    errors = [];
    myList = {} as myvar;
    myDetails = [];

    get reservationsTagsList(): myvar {
        return this.myList;
    }

    get reservationsTagsDetails(): Array<string> {
        return this.myDetails;
    }

    get getReservationsTagsErrors(): Array<string> {
        return this.errors;
    }

    @Mutation
    [Mutations.RESERVATION_TAGS_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_RESERVATION_TAGS_LIST](payload) {
        this.myList = payload;
    }

    @Mutation
    [Mutations.SET_RESERVATION_TAGS_DETAILS](payload) {
        this.myDetails = payload;

    }

    @Action
    [Actions.RESERVATION_TAGS_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/reservationtags/getList", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_RESERVATION_TAGS_LIST, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }

    @Action
    [Actions.RESERVATION_TAGS_DETAILS](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/reservationtags/get", payload)
                .then(({ data }) => {
                    payload = data.data;
                    //console.log(payload);
                    this.context.commit(Mutations.SET_RESERVATION_TAGS_DETAILS, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }

    @Action
    [Actions.RESERVATION_TAGS_INSERT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/reservationtags/insert", credentials)
            //ApiService2.post("/fup/put.php", credentials)
                .then(({ data }) => {
                    if(data.status == 201) {
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.RESERVATION_TAGS_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: Rezervasyon Tag Ekleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.HOTEL_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.RESERVATION_TAGS_EDIT_LIST](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/reservationtags/editList", credentials)
                //ApiService2.post("/fup/put.php", credentials)
                .then(({ data }) => {
                    if(data.status == 201) {
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.RESERVATION_TAGS_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: Rezervasyon Tag Ekleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.HOTEL_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.RESERVATION_TAGS_EDIT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/reservationtags/update", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.RESERVATION_TAGS_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: Rezervasyon Tag güncelleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.RESERVATION_TAGS_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.RESERVATION_TAGS_DELETE](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/reservationtags/delete", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.RESERVATION_TAGS_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: Rezervasyon Tag Silme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.RESERVATION_TAGS_ERROR, myError);
                    reject();
                });
        });
    }
}