import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

interface CompanyBranches {

    data: Array<string>;
}

interface StoreInfo {
    companyBranches: CompanyBranches;
}

@Module
export default class CompanyBranchesModules extends VuexModule implements StoreInfo {
    companyBranches = {} as CompanyBranches;

    /**
     * Get breadcrumb object for current page
     * @returns object
     */
    get BranchList(): CompanyBranches {
        return this.companyBranches;
    }


    @Mutation
    [Mutations.SET_COMPANY_BRANCHES_LIST](payload) {
        this.companyBranches = payload;
    }


    @Action
    [Actions.COMPANY_BRANCHES_LIST](payload) {

        return new Promise<void>((resolve, reject) => {
            ApiService.post("companybranches/getListAll", payload)
                .then(({ data }) => {
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_COMPANY_BRANCHES_LIST, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }



}
