import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/store";

interface myvar {
    data: Array<string>;
}

export interface AccountKickBackInfo {
    myList: myvar;
    myDetails: Array<string>;
    errors: Array<string>;
}

@Module
export default class AccountKickBackModule extends VuexModule implements AccountKickBackInfo {
    errors = [];
    myList = {} as myvar;
    myDetails = [];

    get accountKickBackList(): myvar {
        return this.myList;
    }

    get accountKickBackDetails(): Array<string> {
        return this.myDetails;
    }

    get getAccountKickBackErrors(): Array<string> {
        return this.errors;
    }

    @Mutation
    [Mutations.ACCOUNT_KICKBACK_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_ACCOUNT_KICKBACK_LIST](payload) {
        this.myList = payload;
    }

    @Mutation
    [Mutations.SET_ACCOUNT_KICKBACK_DETAILS](payload) {
        this.myDetails = payload;

    }

    @Action
    [Actions.ACCOUNT_KICKBACK_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/accountkickback/getList", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_ACCOUNT_KICKBACK_LIST, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }



    @Action
    [Actions.ACCOUNT_KICKBACK_DETAILS](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/accountkickback/get", payload)
                .then(({ data }) => {
                    payload = data.data;
                    console.log(payload);
                    this.context.commit(Mutations.SET_ACCOUNT_KICKBACK_DETAILS, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }

    @Action
    [Actions.ACCOUNT_KICKBACK_INSERT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/accountkickback/insert", credentials)
            //ApiService2.post("/fup/put.php", credentials)
                .then(({ data }) => {
                    if(data.status == 201) {
                        if (credentials.AccountID) {
                            const payload = {
                            AccountID: credentials.AccountID
                        }
                            store.dispatch(Actions.ACCOUNT_KICKBACK_LIST, payload);
                        }
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.ACCOUNT_KICKBACK_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: CARİ HESAP KICK-BACK Ekleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.ACCOUNT_KICKBACK_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.ACCOUNT_KICKBACK_EDIT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/accountkickback/update", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {
                        if (credentials.AccountID) {
                            const payload = {
                                AccountID: credentials.AccountID
                            }
                            store.dispatch(Actions.ACCOUNT_KICKBACK_LIST, payload);
                        }
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.ACCOUNT_KICKBACK_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: CARİ HESAP KICK-BACK güncelleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.ACCOUNT_KICKBACK_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.ACCOUNT_KICKBACK_DELETE](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/accountkickback/delete", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {
                        if (credentials.AccountID) {
                            const payload = {
                                AccountID: credentials.AccountID
                            }
                            store.dispatch(Actions.ACCOUNT_KICKBACK_LIST, payload);
                        }
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.ACCOUNT_KICKBACK_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: CARİ HESAP KICK-BACK Silme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.ACCOUNT_KICKBACK_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.ACCOUNT_KICKBACK_ORDER](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/accountkickback/order", payload)
                .then(({ data }) => {
                    //console.log(data);
                    if (payload.AccountID) {
                        store.dispatch(Actions.ACCOUNT_KICKBACK_LIST, {AccountID: payload.AccountID});
                    }
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });
    }
}