import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import {PaymentInfo} from "@/store/modules/PaymentModule";

interface myvar {
    data: Array<string>;
}

export interface RemarkInfo {
    myList: myvar;
    myDetails: Array<string>;
    errors: Array<string>;
}

@Module
export default class RemarkModule extends VuexModule implements RemarkInfo {
    errors = [];
    myList = {} as myvar;
    myDetails = [];

    get getRemarkList(): myvar {
        return this.myList;
    }

    get getRemarkDetails(): Array<string> {
        return this.myDetails;
    }

    get getRemarkErrors(): Array<string> {
        return this.errors;
    }

    @Mutation
    [Mutations.SET_REMAKRS_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_REMAKRS_LIST](payload) {
        this.myList = payload;
    }

    @Mutation
    [Mutations.SET_REMAKRS_DETAILS](payload) {
        this.myDetails = payload;
    }

    @Action
    [Actions.REMARK_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/remarks/getList", payload).then(({ data }) => {
                payload.data = data.data;
                this.context.commit(Mutations.SET_REMAKRS_LIST, payload);
                resolve();
            }).catch(({ response }) => {
                reject();
            });
        });
    }

    @Action
    [Actions.REMARK_INSERT](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/remarks/insert", payload).then(({ data }) => {
                payload.data = data;
                console.log("REMARKS_INSERT data: "+JSON.stringify(data));
                if(data.status == 201) {
                    console.log("REMARK_INSERT resolve");
                    resolve(payload);
                } else{
                    const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                    this.context.commit(Mutations.SET_PAYMENTS_ERROR, myError);
                    console.log("PAYMENT_INSERT reject");
                    reject(payload);
                }
            }).catch(({ response }) => {
                const myError = ["ERROR: Not Ekleme işlemi sırasında beklenmedik bir hata oluştu."];
                this.context.commit(Mutations.SET_PAYMENTS_ERROR, myError);
                reject(payload);
            });
        });
    }

}