import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

interface myvar {
    data: Array<string>;
}

export interface HotelConceptTypesInfo {
    myList: myvar;
    myDetails: Array<string>;
    errors: Array<string>;
}

@Module
export default class HotelConceptTypesModule extends VuexModule implements HotelConceptTypesInfo {
    errors = [];
    myList = {} as myvar;
    myDetails = [];

    get hotelConceptTypesList(): myvar {
        return this.myList;
    }

    get hotelConceptTypesDetails(): Array<string> {
        return this.myDetails;
    }

    get getHotelConceptTypesErrors(): Array<string> {
        return this.errors;
    }

    @Mutation
    [Mutations.HOTEL_CONCEPT_TYPES_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_HOTEL_CONCEPT_TYPES_LIST](payload) {
        this.myList = payload;
    }

    @Mutation
    [Mutations.SET_HOTEL_CONCEPT_TYPES_DETAILS](payload) {
        this.myDetails = payload;

    }

    @Action
    [Actions.HOTEL_CONCEPT_TYPES_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/hotelconcepttypes/getList", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_HOTEL_CONCEPT_TYPES_LIST, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }

    @Action
    [Actions.HOTEL_CONCEPT_TYPES_DETAILS](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/hotelconcepttypes/get", payload)
                .then(({ data }) => {
                    payload = data.data;
                    //console.log(payload);
                    this.context.commit(Mutations.SET_HOTEL_CONCEPT_TYPES_DETAILS, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }

    @Action
    [Actions.HOTEL_CONCEPT_TYPES_INSERT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/hotelconcepttypes/insert", credentials)
            //ApiService2.post("/fup/put.php", credentials)
                .then(({ data }) => {
                    if(data.status == 201) {
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.HOTEL_CONCEPT_TYPES_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: Konsept Kategory Ekleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.HOTEL_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.HOTEL_CONCEPT_TYPES_EDIT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/hotelconcepttypes/update", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.HOTEL_CONCEPT_TYPES_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: Konsept Kategory güncelleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.HOTEL_CONCEPT_TYPES_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.HOTEL_CONCEPT_TYPES_DELETE](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/hotelconcepttypes/delete", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.HOTEL_CONCEPT_TYPES_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: Konsept Kategory Silme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.HOTEL_CONCEPT_TYPES_ERROR, myError);
                    reject();
                });
        });
    }
}