import {App} from "vue";
import axios from "axios";
import VueAxios from "vue-axios";
import JwtService from "@/core/services/JwtService";
import {AxiosResponse, AxiosRequestConfig} from "axios";

/**
 * @description service to call HTTP request via Axios
 */
class ApiService {
    /**
     * @description property to share vue instance
     */
    public static vueInstance: App;

    /**
     * @description initialize vue axios
     */
    public static init(app: App<Element>) {
        ApiService.vueInstance = app;
        ApiService.vueInstance.use(VueAxios, axios);
        //ApiService.vueInstance.axios.defaults.baseURL = "http://localhost:8085";//development
        ApiService.vueInstance.axios.defaults.baseURL = "https://crtbooking.com/api";//production
        ApiService.vueInstance.axios.defaults.headers.common["CGDKEY"] = "CDG-MYCOMP01";
    }

    /**
     * @description set the default HTTP request headers
     */
    public static setHeader(): void {
       // console.log("setHeader V1");
        const userData = JwtService.getUserInfo();
        console.log("setHeader V2 " + JSON.stringify(userData));
        let myData;
        if (typeof userData === "string") {
            myData = JSON.parse(userData);
        }
        //console.log("setHeader V3 " + JSON.stringify(myData));
        ApiService.vueInstance.axios.defaults.headers.common["Authorization"] = `${myData.Logincode}`;
        ApiService.vueInstance.axios.defaults.headers.common["Username"] = `${myData.Username}`;
        //console.log("setHeader V End");
    }

    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static query(
        resource: string,
        params: AxiosRequestConfig
    ): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.get(resource, params).catch((error) => {
            throw new Error(`[KT] ApiService ${error}`);
        });
    }

    /**
     * @description send the GET HTTP request
     * @param resource: string
     * @param slug: string
     * @returns Promise<AxiosResponse>
     */
    public static get(
        resource: string,
        slug = "" as string
    ): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios
            .get(`${resource}/${slug}`)
            .catch((error) => {
                throw new Error(`[KT] ApiService ${error}`);
            });
    }

    /**
     * @description set the POST HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static post(
        resource: string,
        params: AxiosRequestConfig
    ): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.post(`${resource}`, params);
    }

    /**
     * @description send the UPDATE HTTP request
     * @param resource: string
     * @param slug: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static update(
        resource: string,
        slug: string,
        params: AxiosRequestConfig
    ): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.put(`${resource}/${slug}`, params);
    }

    /**
     * @description Send the PUT HTTP request
     * @param resource: string
     * @param params: AxiosRequestConfig
     * @returns Promise<AxiosResponse>
     */
    public static put(
        resource: string,
        params: AxiosRequestConfig
    ): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.put(`${resource}`, params);
    }

    /**
     * @description Send the DELETE HTTP request
     * @param resource: string
     * @returns Promise<AxiosResponse>
     */
    public static delete(resource: string): Promise<AxiosResponse> {
        return ApiService.vueInstance.axios.delete(resource).catch((error) => {
            throw new Error(`[RWV] ApiService ${error}`);
        });
    }
}

export default ApiService;
