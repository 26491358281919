import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import store from "@/store";
import { Mutations, Actions } from "@/store/enums/StoreEnums";
import JwtService from "@/core/services/JwtService";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/dashboard",
    component: () => import("@/layout/Layout.vue"),
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: () => import("@/views/Dashboard.vue"),
      },

      {
        path: "/apps/sys/user",
        name: "user",
        component: () => import("@/views/apps/sys/user/Account.vue"),
        children: [
          {
            path: "addnew",
            name: "useraddnew",
            component: () => import("@/views/apps/sys/user/New.vue"),
          },
          {
            path: "list",
            name: "userlist",
            component: () => import("@/views/apps/sys/user/List.vue"),
          },

          {
            path: "update/:id",
            name: "userupdate",
            component: () => import("@/views/apps/sys/user/Update.vue"),
          }


        ],
      },

      {
        path: "/apps/sys/reservations",
        name: "reservations",
        component: () => import("@/views/apps/sys/reservations/Reservations.vue"),
        children: [
          {
            path: "addnew",
            name: "addnewreservation",
            component: () => import("@/views/apps/sys/reservations/New.vue"),
          },
          {
            path: "addnew2",
            name: "addnewreservation2",
            component: () => import("@/views/apps/sys/reservations/New2.vue"),
          },
          {
            path: "list",
            name: "reservationList",
            component: () => import("@/views/apps/sys/reservations/List.vue"),
          },
          {
            path: "confirmlist",
            name: "reservationConfimList",
            component: () => import("@/views/apps/sys/reservations/ConfirmList.vue"),
          },
          {
            path: "update/:id",
            name: "reservationUpdate",
            component: () => import("@/views/apps/sys/reservations/Update.vue"),
          },
          {
            path: "listflight",
            name: "reservationFlightList",
            component: () => import("@/views/apps/sys/reservations/ListFlight.vue"),
          },
          {
            path: "details/:id",
            name: "reservationDetails",
            component: () => import("@/views/apps/sys/reservations/Details.vue"),
          },
          {
            path: "flights/:id",
            name: "reservationFlights",
            component: () => import("@/views/apps/sys/reservations/Flights.vue"),
          },
          {
            path: "horizontal",
            name: "horizontal",
            component: () => import("@/views/apps/sys/reservations/Horizontal.vue"),
          },
          {
            path: "makereservation",
            name: "makereservation",
            component: () => import("@/views/apps/sys/reservations/MakeReservation.vue"),
          },
          {
            path: "resDetail/:id",
            name: "reservationDetails",
            component: () => import("@/views/apps/sys/reservations/ReservationDetails.vue"),
          },
            /*
          {
            path: "posgarantiform",
            name: "posgarantiform",
            component: () => import("@/views/apps/sys/reservations/PosGarantiForm.vue"),
          },*/
        ],
      },

      {
        path: "/apps/sys/account",
        name: "account",
        component: () => import("@/views/apps/sys/account/Account.vue"),
        children: [
          {
            path: "addnew",
            name: "accountaddnew",
            component: () => import("@/views/apps/sys/account/New3.vue"),
          },
          {
            path: "list",
            name: "accountlist",
            component: () => import("@/views/apps/sys/account/List.vue"),
          },
          {
            path: "update/:id",
            name: "accountupdate",
            component: () => import("@/views/apps/sys/account/Update.vue"),
          },
          {
            path: "kickback/:id",
            name: "accountkickback",
            component: () => import("@/views/apps/sys/account/KickBack.vue"),
          },
        ],
      },

      {
        path: "/apps/sys/hotels",
        name: "hotels",
        component: () => import("@/views/apps/sys/hotels/Hotels.vue"),
        children: [
          {
            path: "addnew",
            name: "hoteladdnew",
            component: () => import("@/views/apps/sys/hotels/New.vue"),
          },
          {
            path: "list",
            name: "hotellist",
            component: () => import("@/views/apps/sys/hotels/List.vue"),
          },
          {
            path: "update/:id",
            name: "hotelupdate",
            component: () => import("@/views/apps/sys/hotels/Update.vue"),
          },
          {
            path: "factsheet/:id/:fcid",
            name: "hotelfactsheet",
            component: () => import("@/views/apps/sys/hotels/FactSheet.vue"),
          },
          {
            path: "roomprices/:id",
            name: "hotelroomprices",
            component: () => import("@/views/apps/sys/hotels/RoomPrices.vue"),
          },
          {
            path: "overcommission/:id",
            name: "overcommission",
            component: () => import("@/views/apps/sys/hotels/OverCommission.vue"),
          },
          {
            path: "profit/:id",
            name: "profit",
            component: () => import("@/views/apps/sys/hotels/Profit.vue"),
          },
          {
            path: "kickbacks/:id",
            name: "kickbacks",
            component: () => import("@/views/apps/sys/hotels/KickBacks.vue"),
          },
          {
            path: "ad/:id",
            name: "ad",
            component: () => import("@/views/apps/sys/hotels/Ad.vue"),
          },
          {
            path: "availability/:id",
            name: "availability",
            component: () => import("@/views/apps/sys/hotels/Availability.vue"),
          },
          {
            path: "actions/:id",
            name: "actions",
            component: () => import("@/views/apps/sys/hotels/Actions.vue"),
          }
        ],
      },
      {
        path: "/apps/sys/flight",
        name: "flight",
        component: () => import("@/views/apps/sys/flight/Flight.vue"),
        children: [
          {
            path: "airline",
            name: "airline",
            component: () => import("@/views/apps/sys/flight/Airline.vue"),
          },
          {
            path: "classes/:id/:alcd",
            name: "airlineclasses",
            component: () => import("@/views/apps/sys/flight/Classes.vue"),
          },
          {
            path: "suppliers",
            name: "supplierlist",
            component: () => import("@/views/apps/sys/flight/suppliers/List.vue"),
          },
          {
            path: "flight_supplier_update/:id",
            name: "flight_supplier_update",
            component: () => import("@/views/apps/sys/flight/suppliers/Update.vue"),
          },
        ],
      },

      {
        path: "/apps/sys/operation",
        name: "transport",
        component: () => import("@/views/apps/sys/operation/Operation.vue"),
        children: [
          {
            path: "transferoperation",
            name: "transferoperation",
            component: () => import("@/views/apps/sys/operation/transfer/TransferOperation.vue"),
          },
          {
            path: "transfer_company",
            name: "transfer_company",
            component: () => import("@/views/apps/sys/operation/transfercompany/List.vue"),
          },
          {
            path: "transfer-company-details/:id",
            name: "transfer_company_details",
            component: () => import("@/views/apps/sys/operation/transfercompany/Details.vue"),
          },
          {
            path: "transfer_area",
            name: "transfer_area",
            component: () => import("@/views/apps/sys/operation/transferlocation/List.vue"),
          },
          {
            path: "transfers",
            name: "transfers",
            component: () => import("@/views/apps/sys/operation/transfer/List.vue"),
          },
          {
            path: "transfer_detail/:id",
            name: "transfer_detail",
            component: () => import("@/views/apps/sys/operation/transfer/Detail.vue"),
          },

        ],
      },


      {
        path: "/apps/sys/accounting",
        name: "accounting",
        component: () => import("@/views/apps/sys/accounting/Accounting.vue"),
        children: [
          {path: "reservationNoninvoice", name: "reservationNoninvoice", component: () => import("@/views/apps/sys/accounting/invoices/noninvoice/List.vue"),},
          {path: "noninvocie/:id", name: "noninvocie", component: () => import("@/views/apps/sys/accounting/invoices/noninvoice/BillStep_2.vue"),},
          {path: "invocie_container", name: "invocie_container", component: () => import("@/views/apps/sys/accounting/invoices/noninvoice/BillStep_3.vue"),},

          {path: "invocies", name: "invocies", component: () => import("@/views/apps/sys/accounting/invoices/invoices/List.vue"),},
        ],
      },

      {
        path: "/apps/sys/payment-methods",
        name: "payment-methods",
        component: () => import("@/views/apps/sys/accounting/payment-methods/PaymentMethods.vue"),
        children: [
          {path: "list", name: "banksLists", component: () => import("@/views/apps/sys/accounting/payment-methods/banks/List.vue"),},
          {path: "bank_accounts", name: "bank_accounts", component: () => import("@/views/apps/sys/accounting/payment-methods/bank-accounts/List.vue"),},
          {path: "bank_pos", name: "bank_pos", component: () => import("@/views/apps/sys/accounting/payment-methods/bank-pos/List.vue"),},
          {path: "pos_detail/:id", name: "pos_detail", component: () => import("@/views/apps/sys/accounting/payment-methods/bank-pos/Detail.vue"),},
          {path: "suppliers", name: "suppliers", component: () => import("@/views/apps/sys/accounting/payment-methods/suppliers/List.vue"),},
          {path: "supplier_update/:id", name: "supplier_update", component: () => import("@/views/apps/sys/accounting/payment-methods/suppliers/Update.vue"),},
        ],
      },

      {
        path: "/apps/sys/logo-codes",
        name: "logo-codes",
        component: () => import("@/views/apps/sys/accounting/logo-codes/VatRates.vue"),
        children: [
          {path: "vat_rates", name: "vat_rates", component: () => import("@/views/apps/sys/accounting/logo-codes/vat-rates/List.vue"),},
          {path: "cost_center", name: "cost_center", component: () => import("@/views/apps/sys/accounting/logo-codes/cost-center/List.vue"),},
        ],
      },

      {
        path: "/apps/sys/mice/pricelists",
        name: "mice-price-lists",
        component: () => import("@/views/apps/sys/mice/pricelists/PriceLists.vue"),
        children: [
          {path: "addnew", name: "pricelistaddnew", component: () => import("@/views/apps/sys/mice/pricelists/PriceListsAddNew.vue"),},
          {path: "list", name: "pricelists", component: () => import("@/views/apps/sys/mice/pricelists/List.vue"),},
          {path: "items/:id", name: "pricelistitems", component: () => import("@/views/apps/sys/mice/pricelists/PriceListItems.vue"),},
        ],
      },

      {
        path: "/apps/sys/mice/requests",
        name: "mice-requests",
        component: () => import("@/views/apps/sys/mice/requests/Requests.vue"),
        children: [
          {path: "addnew", name: "requestsaddnew", component: () => import("@/views/apps/sys/mice/requests/RequestAddNew.vue"),},
          {path: "list", name: "requestslist", component: () => import("@/views/apps/sys/mice/requests/List.vue"),},
          {path: "detail/:id", name: "requestsdetail", component: () => import("@/views/apps/sys/mice/requests/Detail.vue"),},
        ],
      },
      {
        path: "/apps/customers/getting-started",
        name: "apps-customers-getting-started",
        component: () => import("@/views/apps/customers/GettingStarted.vue"),
      },
      {
        path: "/apps/customers/customers-listing",
        name: "apps-customers-listing",
        component: () => import("@/views/apps/customers/CustomersListing.vue"),
      },
      {
        path: "/apps/customers/customer-details",
        name: "apps-customers-details",
        component: () => import("@/views/apps/customers/CustomerDetails.vue"),
      },
      {
        path: "/apps/calendar",
        name: "apps-calendar",
        component: () => import("@/views/apps/Calendar.vue"),
      },
      {
        path: "/apps/chat/private-chat",
        name: "apps-private-chat",
        component: () => import("@/views/apps/chat/Chat.vue"),
      },
      {
        path: "/apps/chat/group-chat",
        name: "apps-group-chat",
        component: () => import("@/views/apps/chat/Chat.vue"),
      },
      {
        path: "/apps/chat/drawer-chat",
        name: "apps-drawer-chat",
        component: () => import("@/views/apps/chat/DrawerChat.vue"),
      },
      {
        path: "/crafted/modals/general/invite-friends",
        name: "modals-general-invite-friends",
        component: () =>
          import("@/views/crafted/modals/general/InviteFriends.vue"),
      },
      {
        path: "/crafted/modals/general/view-user",
        name: "modals-general-view-user",
        component: () => import("@/views/crafted/modals/general/ViewUsers.vue"),
      },
      {
        path: "/crafted/modals/general/upgrade-plan",
        name: "modals-general-upgrade-plan",
        component: () =>
          import("@/views/crafted/modals/general/UpgradePlan.vue"),
      },
      {
        path: "/crafted/modals/general/share-and-earn",
        name: "modals-general-share-and-earn",
        component: () =>
          import("@/views/crafted/modals/general/ShareAndEarn.vue"),
      },
      {
        path: "/crafted/modals/forms/new-target",
        name: "modals-forms-new-target",
        component: () => import("@/views/crafted/modals/forms/NewTarget.vue"),
      },
      {
        path: "/crafted/modals/forms/new-card",
        name: "modals-forms-new-card",
        component: () => import("@/views/crafted/modals/forms/NewCard.vue"),
      },
      {
        path: "/crafted/modals/forms/new-address",
        name: "modals-forms-new-address",
        component: () => import("@/views/crafted/modals/forms/NewAddress.vue"),
      },
      {
        path: "/crafted/modals/forms/create-api-key",
        name: "modals-forms-create-api-key",
        component: () =>
          import("@/views/crafted/modals/forms/CreateApiKey.vue"),
      },
      {
        path: "/crafted/modals/wizards/two-factor-auth",
        name: "modals-wizards-two-factor-auth",
        component: () =>
          import("@/views/crafted/modals/wizards/TwoFactorAuth.vue"),
      },
      {
        path: "/crafted/modals/wizards/create-app",
        name: "modals-wizards-create-app",
        component: () => import("@/views/crafted/modals/wizards/CreateApp.vue"),
      },
      {
        path: "/crafted/modals/wizards/create-account",
        name: "modals-wizards-create-account",
        component: () =>
          import("@/views/crafted/modals/wizards/CreateAccount.vue"),
      },
      {
        path: "/crafted/widgets/lists",
        name: "widgets-list",
        component: () => import("@/views/crafted/widgets/Lists.vue"),
      },
      {
        path: "/crafted/widgets/statistics",
        name: "widgets-statistics",
        component: () => import("@/views/crafted/widgets/Statistics.vue"),
      },
      {
        path: "/crafted/widgets/charts",
        name: "widgets-charts",
        component: () => import("@/views/crafted/widgets/Charts.vue"),
      },
      {
        path: "/crafted/widgets/mixed",
        name: "widgets-mixed",
        component: () => import("@/views/crafted/widgets/Mixed.vue"),
      },
      {
        path: "/crafted/widgets/tables",
        name: "widgets-tables",
        component: () => import("@/views/crafted/widgets/Tables.vue"),
      },
      {
        path: "/crafted/widgets/feeds",
        name: "widgets-feeds",
        component: () => import("@/views/crafted/widgets/Feeds.vue"),
      },
    ],
  },
  {
    path: "/",
    component: () => import("@/components/page-layouts/Auth.vue"),
    children: [
      {
        path: "/sign-in",
        name: "sign-in",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignIn.vue"),
      },
      {
        path: "/sign-up",
        name: "sign-up",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/SignUp.vue"),
      },
      {
        path: "/password-reset",
        name: "password-reset",
        component: () =>
          import("@/views/crafted/authentication/basic-flow/PasswordReset.vue"),
      },
    ],
  },




  {
    // the 404 route, when none of the above matches
    path: "/404",
    name: "404",
    component: () => import("@/views/crafted/authentication/Error404.vue"),
  },
  {
    path: "/500",
    name: "500",
    component: () => import("@/views/crafted/authentication/Error500.vue"),
  },
  {
    path: "/PosResult",
    name: "PosResult",
    component: () => import("@/views/PosResult.vue"),
  },
  {
    path: "/PosGarantiForm",
    name: "PosGarantiForm",
    component: () => import("@/views/apps/sys/reservations/PosGarantiForm.vue"),
  },
  {
    path: "/PosNomuPayForm",
    name: "PosNomuPayForm",
    component: () => import("@/views/apps/sys/reservations/PosNomuPayForm.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];

const router = createRouter({
  history: createWebHashHistory(),

  routes,
});

router.beforeEach(() => {
  // reset config to initial state
  store.commit(Mutations.RESET_LAYOUT_CONFIG);

  store.dispatch(Actions.VERIFY_AUTH, { Authorization: JwtService.getToken(), Username:JwtService.getUserName() });

  // Scroll page to top on every route change
  setTimeout(() => {
    window.scrollTo(0, 0);
  }, 100);
});

export default router;
