import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/store";

interface myvar {
    data: Array<string>;
}

export interface FilesInfo {
    myList: myvar;
    myDetails: Array<string>;
    mySelected: Array<string>;
    errors: Array<string>;
}

@Module
export default class FilesModule extends VuexModule implements FilesInfo {
    errors = [];
    myList = {} as myvar;
    myDetails = [];
    mySelected = [];

    get filesList(): myvar {
        return this.myList;
    }

    get selectedFiles(){
        return this.mySelected;
    }

    get filesDetails(): Array<string> {
        return this.myDetails;
    }

    get getFilesErrors(): Array<string> {
        return this.errors;
    }

    @Mutation
    [Mutations.FILES_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_FILES_LIST](payload) {
        this.myList = payload;
    }

    @Mutation
    [Mutations.SET_FILES_DETAILS](payload) {
        this.myDetails = payload;

    }

    @Mutation
    [Mutations.SET_FILES_SELECTED](payload) {
        this.mySelected = payload;
    }

    @Action
    [Actions.FILES_SELECTED](payload) {
        this.context.commit(Mutations.SET_FILES_SELECTED, payload);
    }

    @Action
    [Actions.FILES_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/files/getList", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_FILES_LIST, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }



    @Action
    [Actions.FILES_DETAILS](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/files/get", payload)
                .then(({ data }) => {
                    payload = data.data;
                    //console.log(payload);
                    this.context.commit(Mutations.SET_FILES_DETAILS, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }

    @Action
    [Actions.FILES_INSERT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/files/insert", credentials)
            //ApiService2.post("/fup/put.php", credentials)
                .then(({ data }) => {
                    if(data.status == 201) {
                        const payload = {
                            Type: credentials.Type,
                            ParentID: credentials.ParentID,
                            ChildID: credentials.ChildID,
                            SubChildID: credentials.SubChildID,
                        }
                        store.dispatch(Actions.FILES_LIST, payload);
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.FILES_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: Dosya Kategori Ekleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.FILES_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.FILES_EDIT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/files/update", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {
                        store.dispatch(Actions.FILES_LIST, {});
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.FILES_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: Dosya Kategori güncelleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.FILES_ERROR, myError);
                    reject();
                });
        });
    }
    @Action
    [Actions.FILES_ORDER](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/files/order", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {
                        const payload = {
                            Type: credentials.Type,
                            ParentID: credentials.ParentID,
                            ChildID: credentials.ChildID,
                            SubChildID: credentials.SubChildID,
                        }
                        store.dispatch(Actions.FILES_LIST, payload);
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.FILES_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: Dosya Kategori güncelleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.FILES_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.FILES_DELETE](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/files/delete", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {
                        const payload = {
                            Type: credentials.Type,
                            ParentID: credentials.ParentID,
                            ChildID: credentials.ChildID,
                            SubChildID: credentials.SubChildID,
                        }
                        store.dispatch(Actions.FILES_LIST, payload);
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.FILES_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: Dosya Kategori Silme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.FILES_ERROR, myError);
                    reject();
                });
        });
    }
}