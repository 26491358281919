import { Action, Mutation, Module, VuexModule } from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import ApiService from "@/core/services/ApiService";

interface CompanyTitles {

    data: Array<string>;
}

interface StoreInfo {
    companyTitles: CompanyTitles;
}

@Module
export default class CompanyTitlesModules extends VuexModule implements StoreInfo {
    companyTitles = {} as CompanyTitles;

    /**
     * Get breadcrumb object for current page
     * @returns object
     */
    get TitleList(): CompanyTitles {
        return this.companyTitles;
    }


    @Mutation
    [Mutations.SET_COMPANY_TITLES_LIST](payload) {
        this.companyTitles = payload;
    }

    @Action
    [Actions.COMPANY_TITLES_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("companytitles/getListAll", payload)
                .then(({ data }) => {
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_COMPANY_TITLES_LIST, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }
}
