import ApiService from "@/core/services/ApiService";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";
import store from "@/store";

interface myvar {
    data: Array<string>;
}

export interface MiceRequestsInfo {
    myList: myvar;
    myDetails: Array<string>;
    mySelected: Array<string>;
    errors: Array<string>;
}

@Module
export default class MiceRequestsModule extends VuexModule implements MiceRequestsInfo {
    errors = [];
    myList = {} as myvar;
    myDetails = [];
    mySelected = [];

    get miceRequestsList(): myvar {
        return this.myList;
    }

    get miceRequestsDetails(): Array<string> {
        return this.myDetails;
    }
    get selectedMiceRequests(){
        return this.mySelected;
    }
    get getMiceRequestsErrors(): Array<string> {
        return this.errors;
    }

    @Mutation
    [Mutations.MICE_REQUESTS_ERROR](error) {
        this.errors = error;
    }

    @Mutation
    [Mutations.SET_MICE_REQUESTS_LIST](payload) {
        this.myList = payload;
    }

    @Mutation
    [Mutations.SET_MICE_REQUESTS_DETAILS](payload) {
        this.myDetails = payload;

    }
    @Mutation
    [Mutations.SET_MICE_REQUESTS_SELECTED](payload) {
        this.mySelected = payload;
    }

    @Action
    [Actions.MICE_REQUESTS_SELECTED](payload) {
        this.context.commit(Mutations.SET_MICE_REQUESTS_SELECTED, payload);
    }

    @Action
    [Actions.MICE_REQUESTS_LIST](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/micerequests/getList", payload)
                .then(({ data }) => {
                    //console.log(data);
                    payload.data = data.data;
                    this.context.commit(Mutations.SET_MICE_REQUESTS_LIST, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }

    @Action
    [Actions.MICE_REQUESTS_DETAILS](payload) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/micerequests/get", payload)
                .then(({ data }) => {
                    payload = data.data;
                    //console.log(payload);
                    this.context.commit(Mutations.SET_MICE_REQUESTS_DETAILS, payload);
                    resolve();
                })
                .catch(({ response }) => {
                    reject();
                });
        });

    }

    @Action
    [Actions.MICE_REQUESTS_INSERT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/micerequests/insert", credentials)
            //ApiService2.post("/fup/put.php", credentials)
                .then(({ data }) => {
                    if(data.status == 201) {
                        store.dispatch(Actions.MICE_REQUESTS_LIST, {});
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.MICE_REQUESTS_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: MICE TALEP Ekleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.MICE_REQUESTS_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.MICE_REQUESTS_EDIT](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/micerequests/update", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {
                        store.dispatch(Actions.MICE_REQUESTS_LIST, {});
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.MICE_REQUESTS_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: MICE TALEP güncelleme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.MICE_REQUESTS_ERROR, myError);
                    reject();
                });
        });
    }

    @Action
    [Actions.MICE_REQUESTS_DELETE](credentials) {
        return new Promise<void>((resolve, reject) => {
            ApiService.post("/micerequests/delete", credentials)
                .then(({ data }) => {
                    if(data.status == 200) {
                        store.dispatch(Actions.MICE_REQUESTS_LIST, {});
                        resolve();
                    }else{
                        const myError = ['ERROR: ' + data.messages + ' ('+data.code+')'];
                        this.context.commit(Mutations.MICE_REQUESTS_ERROR, myError);
                        reject();
                    }
                })
                .catch(({ response }) => {
                    const myError = ["ERROR: MICE TALEP Silme işlemi sırasında beklenmedik bir hata oluştu."];
                    this.context.commit(Mutations.MICE_REQUESTS_ERROR, myError);
                    reject();
                });
        });
    }

}